import React, { Component } from "react";
import { Link } from "react-router-dom";
import Subscribe from "src/components/Subscribe";
import GoogleReviews from "src/components/googleReviews";
import { Helmet } from "react-helmet";
import { Box, Typography } from "@mui/material";
import FAQItem from "src/components/FAQS";
import {
  carPolishing,
  carWindowTinting,
  CeramicCoating,
} from "src/components/FAQS/mockData";
import CallSection2 from "src/reusables/callSection2";
export class index extends Component {
  render() {
    return (
      <div>
         <Helmet>
              <title> Best Ceramic Coating for Cars in Calgary | T-Bros Auto Detailing</title>
    
              <meta
                name="description"
                content="Protect your car with XPEL ceramic coating in Calgary. T-Bros Auto Detailing offers premium auto ceramic coating services for a lasting shine. Call us now!"
              />
              <meta
                name="keywords"
                content="calgary ceramic coating, car ceramic coating, xpel ceramic coating, best ceramic coating for cars, auto ceramic coating, ceramic coating"
              />
            
            </Helmet>
      
        <section className="about-page about1">
          {/* Top banner section */}
          <div className="Ceramic ">
            <div className="container">
              <div className="row">
                <div className="title-inner">
                  <h1
                    style={{
                      color: "var(--yellow)",
                      textTransform: "capitalize",
                    }}
                  >
                    Ceramic Coating in Calgary
                  </h1>
                  <p>
                    Worried About Protecting Your Car’s Expensive Paint?
                    <br />
                    Sleep Easy with the Best Ceramic Coating in Calgary
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="container mt-6 wash1">
            <div className="row justify-content-between align-items-center">
              <div className="col-lg-7 col-md-12 col-sm-12">
                <h2 style={{ fontWeight: 600 }}>
                  What is{" "}
                  <span style={{ color: "var(--blue)" }}>
                    {" "}
                     Ceramic Coating?
                  </span>
                </h2>
                <br />
                <p>
                  Ceramic coating is a liquid polymer applied to the exterior
                  surface of a vehicle to protect its paint from environmental
                  contaminants such as tree sap, bird droppings, and more. The
                  coating forms a hydrophobic layer over the paint, making it
                  easier to clean while repelling dust and moisture.<strong style={{color:"#234DEF"}}> Ceramic
                  coating in Calgary</strong> not only increases the lifespan of your
                  car’s paint but also provides a smooth, glossy finish that
                  enhances its appearance.
                </p>
                {/* <div className="link-btn widhtbtn mt-5">
                  <a href="#" className="btnform">
                    Our Services
                  </a>
                </div> */}
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12">
                <img
                  className="img-fluid"
                  src="assets/img/CeramicCoating1.png"
                  data-aos="fade-right"
                  alt="calgary ceramic coating"
                />
              </div>
            </div>
          </div>

          <section className="about11 mt-6 p-text-fonts">
            <div className="container">
              <div className="row justify-content-between align-items-center">
                <div className="col-lg-7 col-md-12 col-sm-12 mb-3">
                  <img
                    className="imgwidth"
                    alt="car ceramic coating"
                    src="/assets/img/CeramicCoating2.png"
                    data-aos="fade-right"
                  />
                </div>
                <div className="col-lg-5 col-md-12 col-sm-12">
                  <h2 style={{ fontWeight: 600 }}>
                    How Does{" "}
                    <span style={{ color: "#234DEF" }}>Ceramic Coating </span>{" "}
                    Work on Your Car?
                  </h2>
                  <br />
                  <p>
                    The main component for car <strong style={{color:"#234DEF"}}> ceramic coating in Calgary</strong>{" "}
                    consists of an active ingredient called silicon dioxide,
                    titanium dioxide, and solvents or additives. When applied,
                    the silicon dioxide forms a chemical bond with the car's
                    paint, creating a tough, transparent layer that protects
                    against contaminants, UV damage, and minor abrasions while
                    enhancing the paint's shine.
                  </p>

                  {/* <div className="link-btn widhtbtn mt-5">
                    <Link to="/booking">MAKE AN APPOINTMENT</Link>
                  </div> */}
                </div>
              </div>
            </div>
          </section>

          <div className="container">
            <div className="row mb-2 mt-5 text-center bold-text">
              <h2>
                Is{" "}
                <span style={{ color: "var(--blue)" }}>
                  {" "}
                  Ceramic Coating in Calgary
                </span>{" "}
                Worth It?
              </h2>
              <p>
                Considering the harsh climatic conditions and the rough winters,
                getting{" "}
                <strong style={{ color: "#234DEF" }}>
                  {" "}
                  ceramic coating in Calgary
                </strong>{" "}
                for your vehicle becomes even more crucial. It provides
                essential protection against environmental factors, preserving
                your car’s paint and surface while ensuring it stays in top
                condition year-round.
              </p>
              <p></p>
            </div>
          </div>
          <section
            style={{ marginBottom: "111px" }}
            className="yellow-bg7  mt-5 "
          >
            <div className="container">
              <div className="row justify-content-between align-items-center">
                <div className="col-lg-6 col-md-12 col-sm-12 color-white py-5">
                  <h2
                    style={{
                      fontWeight: "bold",
                      textAlign: "left",
                      color: "var(--blue)",
                      paddingBottom: "10px",
                    }}
                  >
                    Car ceramic coating in Calgary{" "}
                    <span style={{ color: "var(--black)" }}>
                      benefits in the following ways:
                    </span>
                  </h2>

                  <div className="video-text035">
                    <div className="row">
                      <div className="col-1">
                        <svg
                          width="21"
                          height="21"
                          viewBox="0 0 21 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            cx="10.2842"
                            cy="10.5"
                            r="10.2842"
                            fill="white"
                          />
                          <circle
                            cx="10.3756"
                            cy="10.4483"
                            r="6.80527"
                            fill="#234DEF"
                          />
                        </svg>
                      </div>
                      <div className="col-10">
                        <h5 style={{ fontWeight: "900", color:"#234DEF" }}>
                          Protects the paint against harsh weather conditions.
                        </h5>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-1">
                        <svg
                          width="21"
                          height="21"
                          viewBox="0 0 21 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            cx="10.2842"
                            cy="10.5"
                            r="10.2842"
                            fill="white"
                          />
                          <circle
                            cx="10.3756"
                            cy="10.4483"
                            r="6.80527"
                            fill="#234DEF"
                          />
                        </svg>
                      </div>
                      <div className="col-10">
                        <h5 style={{ fontWeight: "900",color:"#234DEF" }}>
                          Repels water and dirt making your car easier to clean
                          and maintain.
                        </h5>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-1">
                        <svg
                          width="21"
                          height="21"
                          viewBox="0 0 21 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            cx="10.2842"
                            cy="10.5"
                            r="10.2842"
                            fill="white"
                          />
                          <circle
                            cx="10.3756"
                            cy="10.4483"
                            r="6.80527"
                            fill="#234DEF"
                          />
                        </svg>
                      </div>
                      <div className="col-10">
                        <h5 style={{ fontWeight: "900", color:"#234DEF" }}>
                          Protects against minor scratches, chemicals and swirl
                          marks.
                        </h5>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-1">
                        <svg
                          width="21"
                          height="21"
                          viewBox="0 0 21 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            cx="10.2842"
                            cy="10.5"
                            r="10.2842"
                            fill="white"
                          />
                          <circle
                            cx="10.3756"
                            cy="10.4483"
                            r="6.80527"
                            fill="#234DEF"
                          />
                        </svg>
                      </div>
                      <div className="col-10">
                        <h5 style={{ fontWeight: "900",color:"#234DEF" }}>
                          Provides a glossy appearance, giving your car a
                          showroom-like shine.
                        </h5>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-1">
                        <svg
                          width="21"
                          height="21"
                          viewBox="0 0 21 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            cx="10.2842"
                            cy="10.5"
                            r="10.2842"
                            fill="white"
                          />
                          <circle
                            cx="10.3756"
                            cy="10.4483"
                            r="6.80527"
                            fill="#234DEF"
                          />
                        </svg>
                      </div>
                      <div className="col-10">
                        <h5 className="" style={{ fontWeight: "900", color:"#234DEF" }}>
                          Ceramic coating in Calgary can last years, ensuring
                          consistent protection and appearance.
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 col-md-12 col-sm-12">
                  <div className="img-car4">
                    <img
                      className="img-fluid rounded"
                      alt="best ceramic coating for cars"
                      src="/assets/img/CeramicCoating2121.png"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="mt-5">
            <CallSection2 />
          </div>
          <div className="container">
            <Box sx={{ my: 10 }}>
              <Typography
                variant="h2"
                sx={{ display: "flex", justifyContent: "center", fontSize: 50 }}
                gutterBottom
              >
                FAQ’s
              </Typography>
              {CeramicCoating.map((item) => (
                <FAQItem
                  key={item.id}
                  question={item.question}
                  answer={item.answer}
                />
              ))}
            </Box>
          </div>
          <GoogleReviews />
          <Subscribe />
        </section>
      </div>
    );
  }
}

export default index;
