import HomeIcon from "@mui/icons-material/Home";
import SettingsIcon from "@mui/icons-material/Settings";
import AboutIcon from "@mui/icons-material/Info";
import ContactIcon from "@mui/icons-material/ContactPage";
import HolidayVillageIcon from "@mui/icons-material/HolidayVillage";
import DescriptionIcon from "@mui/icons-material/Description";
type SidebarMenuObject = {
  title?: string;
  path?: string;
  color?: string;
  disabled?: boolean;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  children?: SidebarMenuObject[];
};

export const menus: SidebarMenuObject[] = [
  {
    title: "Home",
    path: "/",
    startIcon: <HomeIcon />,
  },
  {
    title: "Services",
    path: "",
    disabled: true,
    startIcon: <SettingsIcon />,
    children: [
      {
        title: "Interior Detailing",
        path: "interior-detailing",
      },
      {
        title: "Exterior Detailing",
        path: "exterior-detailing",
      },
      {
        title: "Car Polishing",
        path: "car-polishing",
      },
      {
        title: "Touch Up Paint",
        path: "touch-up-paint",
      },
      {
        title: "Undercoating Rust Protection",
        path: "undercoating-rust-protection",
      },
      {
        title: "Headlight Restoration",
        path: "headlight-restoration",
      },
      {
        title: "Paint Protection Films",
        path: "paint-protection-films",
      },
      // {
      //   title: "Ceramic",
      //   path: "",
      // },
      {
        title: "Tinting",
        path: "window-tinting",
      },
      {
        title: "Ceramic Coating",
        path: "ceramic-coating",
      },
    ],
  },
  {
    title: "About Us",
    path: "/about-us",
    startIcon: <AboutIcon />,
  },

  {
    title: "Why Choose us",
    path: "/why-choose",
    startIcon: <HolidayVillageIcon />,
  },
  {
    title: "Blog",
    path: "/blog",
    startIcon: <DescriptionIcon />,
  },
  {
    title: "Contact Us",
    path: "/contact-us",
    startIcon: <ContactIcon />,
  },
  
];

export const activeServiceMenu = menus
  ?.find((ele) => ele?.title == "Services")
  ?.children?.map((item) => item?.path);
