import { Box, Typography, useTheme, Fab } from "@mui/material";
import CallIcon from "@mui/icons-material/Call";

const CallSection2 = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: theme?.colors?.primary.main,
        display: "flex",
        textAlign: "center",
        flexWrap: "wrap",
        padding: "46px",
        flexDirection: "row",

        alignItems: "center",
        justifyContent: "space-around",
        gap: 5,
      }}
    >
      <Typography
        gutterBottom
        variant="subtitle1"
        component="div"
        sx={{
          fontSize: "33px",
          lineHeight: "3rem",
          fontWeight: 900,
          textAlign: "start",
          color: theme?.colors?.alpha?.black[100],
        }}
      >
        Are you looking for a long-term <br className="p-0 m-0 " /> solution to
        protect your car?
        <Typography
          gutterBottom
          variant="subtitle1"
          component="div"
          sx={{
            fontSize: "16px",
            marginTop:"20px",
            textAlign: "start",
            color: "#234DEF",
            fontWeight: 900,
          }}
        >
          Get ceramic coating in Calgary today and enjoy unmatched
          <br className="p-0 m-0 " /> protection and shine for your vehicle!
        </Typography>
      </Typography>

      <Fab
        href="tel:+1 587-999-8722"
        variant="extended"
        sx={{
          backgroundColor: theme?.colors?.alpha.white[100],
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
          fontSize: 20,
        }}
      >
        <CallIcon sx={{ mr: 1 }} />
        +1 587-999-8722
      </Fab>
    </Box>
  );
};

export default CallSection2;
