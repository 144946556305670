import React, { Suspense, lazy } from "react";
import { Navigate } from "react-router-dom";
import { RouteObject } from "react-router";
import SuspenseLoader from "src/components/SuspenseLoader";
import WebLayout from "./layouts/WebLayout";
import UserLayout from "./layouts/UserLayout";
import SidebarLayout from "./layouts/SidebarLayout";
import Home from "src/views/website/home";
import WhyChoose from "src/views/website/whyChoose";
import Blog from "src/views/website/blogs";
import BlogDetail from "src/views/website/blogs/detail";
import ContactUs from "src/views/website/contactUs";
import InteriorDetailing from "src/views/website/interiorDetailing";
import ExteriorDetailing from "src/views/website/exteriorDetailing";
import CarPolishing from "src/views/website/carPolishing";
import TouchUpPaint from "src/views/website/touchUpPaint";
import UndercoatingRustProtection from "src/views/website/undercoatingRustProtection";
import AboutUs from "src/views/website/aboutUs";
import HeadlightRestoration from "src/views/website/headlightRestoration";
import PaintProtection from "src/views/website/PaintProtection";
import CarWindowTinting from "src/views/website/carWindowTinting";
import CeramicCoating from "src/views/website/ceramicCoating";

const Loader = (Component) => (props) =>
(
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);
// Authentications //

// Import
const LoginScreen = Loader(
  lazy(() => import("src/views/authentication/login"))
);

const Booking = Loader(lazy(() => import("src/views/onlineBooking/booking")));
const BookingSuccess = Loader(
  lazy(() => import("src/views/onlineBooking/bookingSuccess"))
);
const BookingCancel = Loader(
  lazy(() => import("src/views/onlineBooking/BookingCancel"))
);
const ConfirmBooking = Loader(
  lazy(() => import("src/views/onlineBooking/confirmBooking"))
);
const BookingSlots = Loader(
  lazy(() => import("src/views/onlineBooking/bookingSlots"))
);

const NotFound = Loader(lazy(() => import("src/views/notFound"))); // Create a NotFound component

// Admin //

const DashboardCrypto = Loader(lazy(() => import("src/views/admin/dashboard")));
const Bookings = Loader(lazy(() => import("src/views/admin/bookings")));
const Categories = Loader(lazy(() => import("src/views/admin/categories"))); // Fixed typo "Cateogries" to "Categories"
const Services = Loader(lazy(() => import("src/views/admin/services")));
const Addons = Loader(lazy(() => import("src/views/admin/addons")));
const DisabledSlots = Loader(
  lazy(() => import("src/views/admin/disabledSlots"))
);

const routes = (role?: string, isLoggedIn: boolean = false): RouteObject[] => {
  let routesList: RouteObject[] = [
    {
      path: "",
      element: <WebLayout />,
      children: [
        {
          path: "",
          element: <Home />,
        },
        {
          path: "why-choose",
          element: <WhyChoose />,
        },
        {
          path: "about-us",
          element: <AboutUs />,
        },
        {
          path: "blog",
          element: <Blog />,
        },
        {
          path: "blog/:id?",
          element: <BlogDetail />,
        },
        {
          path: "contact-us",
          element: <ContactUs />,
        },
        {
          path: "*",
          element: <NotFound />,
        },
        {
          path: "/",
          children: [
            {
              path: "interior-detailing",
              element: <InteriorDetailing />,
            },
            {
              path: "exterior-detailing",
              element: <ExteriorDetailing />,
            },
            {
              path: "car-polishing",
              element: <CarPolishing />,
            },
            {
              path: "touch-up-paint",
              element: <TouchUpPaint />,
            },
            {
              path: "undercoating-rust-protection",
              element: <UndercoatingRustProtection />,
            },
            {
              path: "headlight-restoration",
              element: <HeadlightRestoration />,
            },
            {
              path: "paint-protection-films",
              element: <PaintProtection />,
            },
            {
              path: "window-tinting",
              element: <CarWindowTinting />,
            },
            {
              path: "ceramic-coating",
              element: <CeramicCoating />,
            },
            {
              path: "/",
              element: <Navigate to="/not-found" replace />,
            },
          ],
        },
      ],
    },
    {
      path: "",
      element: <UserLayout />,
      children: [
        {
          path: "booking",
          element: <Booking />,
        },
        {
          path: "confirm-booking",
          element: <ConfirmBooking />,
        },
        {
          path: "booking-success",
          element: <BookingSuccess />,
        },
        {
          path: "booking-cancel",
          element: <BookingCancel />,
        },
        {
          path: "choose-slot",
          element: <BookingSlots />,
        },
      ],
    },
  ];

  if (!isLoggedIn) {
    routesList = [
      ...routesList,
      {
        path: "admin/*",
        element: <Navigate to="/login" replace />,
      },
      {
        path: "login",
        element: <LoginScreen />,
      },
    ];
  }

  if (isLoggedIn && role === "admin") {
    routesList = [
      {
        path: "login",
        element: <Navigate to="/admin/bookings" replace />,
      },
      ...routesList,
      {
        path: "admin",
        element: <Navigate to="bookings" replace />,
      },
      {
        path: "admin",
        element: <SidebarLayout />,
        children: [
          {
            path: "bookings",
            element: <Bookings />,
          },
          {
            path: "",
            children: [
              {
                path: "categories",
                element: <Categories />,
              },
              {
                path: "services",
                element: <Services />,
              },
              {
                path: "addons",
                element: <Addons />,
              },
              {
                path: "disabled-slots",
                element: <DisabledSlots />,
              },
            ],
          },
        ],
      },
    ];
  }

  return routesList;
};

export default routes;
